<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="8" :sm="24">
              <a-form-item label="责任对象类型" prop="kind">
                <a-select placeholder="请选择责任对象类型" v-model="queryParam.kind" style="width: 100%" allow-clear>
                  <a-select-option v-for="(d, index) in kindOptions" :key="index" :value="d.dictValue">{{
                    d.dictLabel
                  }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="责任对象编号" prop="kindNo">
                <a-input v-model="queryParam.kindNo" placeholder="请输入责任对象编号" allow-clear />
              </a-form-item>
            </a-col>
            <template v-if="advanced">
              <a-col :md="8" :sm="24">
                <a-form-item label="责任人姓名" prop="employeeName">
                  <a-input v-model="queryParam.employeeName" placeholder="请输入责任人姓名" allow-clear />
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="责任人开始负责日期">
                  <a-range-picker
                    style="width: 100%"
                    v-model="daterangeStartDate"
                    valueFormat="YYYY-MM-DD"
                    format="YYYY-MM-DD"
                    allow-clear
                  />
                </a-form-item>
              </a-col>
            </template>
            <a-col :md="(!advanced && 8) || 24" :sm="24">
              <span
                class="table-page-search-submitButtons"
                :style="(advanced && { float: 'right', overflow: 'hidden' }) || {}"
              >
                <a-button type="primary" @click="handleQuery" id="tableQueryBtn"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
                <a @click="toggleAdvanced" style="margin-left: 8px">
                  {{ advanced ? '收起' : '展开' }}
                  <a-icon :type="advanced ? 'up' : 'down'" />
                </a>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <a-toolbar :loading="loading" refresh @refreshQuery="getList" tid="1">
        <div class="table-operations">
          <a-button type="primary" @click="$refs.createForm.handleAdd()" v-hasPermi="['tenant:iot:principals:add']">
            <a-icon type="plus" />新增
          </a-button>
          <a-button
            type="primary"
            :disabled="single"
            @click="$refs.createForm.handleUpdate(undefined, ids)"
            v-hasPermi="['tenant:iot:principals:edit']"
          >
            <a-icon type="edit" />修改
          </a-button>
          <!-- <a-button type="danger" :disabled="multiple" @click="handleDelete" v-hasPermi="['tenant:iot:principals:remove']">
          <a-icon type="delete" />删除
        </a-button> -->
          <a-button type="primary" @click="handleExport" v-hasPermi="['tenant:iot:principals:export']">
            <a-icon type="download" />导出
          </a-button>
          <!-- <a-button
          type="dashed"
          shape="circle"
          :loading="loading"
          :style="{float: 'right'}"
          icon="reload"
          @click="getList" /> -->
        </div>
      </a-toolbar>
      <!-- 增加修改 -->
      <create-form ref="createForm" :kindOptions="kindOptions" :statusOptions="statusOptions" @ok="getList" />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        tid="1"
        :columns="columns"
        :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false"
      >
        <span slot="kind" slot-scope="text, record" :title="kindFormat(record)">
          {{ kindFormat(record) }}
        </span>
        <span slot="startDate" slot-scope="text, record">
          {{ parseDateTime(record.startDate, 'yyyy-MM-dd') }}
        </span>
        <span slot="endDate" slot-scope="text, record">
          {{ parseDateTime(record.endDate, 'yyyy-MM-dd') }}
        </span>
        <span slot="operation" slot-scope="text, record">
          <a-divider type="vertical" v-hasPermi="['tenant:iot:principals:edit']" />
          <a @click="$refs.createForm.handleUpdate(record, undefined)" v-hasPermi="['tenant:iot:principals:edit']">
            <a-icon type="edit" />修改
          </a>
          <a-divider type="vertical" v-hasPermi="['tenant:iot:principals:remove']" />
          <!-- <a @click="handleDelete(record)" v-hasPermi="['tenant:iot:principals:remove']">
            <a-icon type="delete" />删除
          </a> -->
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="(total) => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { listPrincipals, delPrincipals, exportPrincipals } from '@/api/iot/principals'
import CreateForm from './modules/CreateForm'

export default {
  name: 'Principals',
  components: {
    CreateForm
  },
  data() {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 责任对象类型 责任人负责的对象，系统支持的需要有责任人的对象，目前是车或设备。责任对象是车时，kind=vehicle；责任对象是设备时，kind=equipment。字典
      kindOptions: [],
      // 责任人开始负责日期 此处有两种情况：1.是一个日期；2.“至今”，一辆车中只允许有一个“至今”的责任人时间范围
      daterangeStartDate: [],
      // 责任人结束负责日期时间范围
      daterangeEndDate: [],
      // 是否删除 0否1是字典
      statusOptions: [],
      // 查询参数
      queryParam: {
        kind: null,
        kindNo: null,
        employeeName: null,
        startDate: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: '责任类型',
          dataIndex: 'kind',
          scopedSlots: { customRender: 'kind' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '自编号',
          dataIndex: 'kindNo',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '责任人姓名',
          dataIndex: 'employeeName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '开始负责日期',
          dataIndex: 'startDate',
          scopedSlots: { customRender: 'startDate' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '结束负责日期',
          dataIndex: 'endDate',
          scopedSlots: { customRender: 'endDate' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ]
    }
  },
  filters: {},
  created() {
    this.getList()
    this.getDicts('iot_principals_type').then((response) => {
      this.kindOptions = response.data
    })
    this.getDicts('iot_del_yesno').then((response) => {
      this.statusOptions = response.data
    })
  },
  computed: {},
  watch: {},
  methods: {
    /** 查询责任人列表 */
    getList() {
      this.loading = true
      this.queryParam.params = {}
      if (this.daterangeStartDate !== null && this.daterangeStartDate !== '' && this.daterangeStartDate.length !== 0) {
        this.queryParam.params['beginStartDate'] = this.daterangeStartDate[0]
        this.queryParam.params['endStartDate'] = this.daterangeStartDate[1]
      }
      if (this.daterangeEndDate !== null && this.daterangeEndDate !== '' && this.daterangeEndDate.length !== 0) {
        this.queryParam.params['beginEndDate'] = this.daterangeEndDate[0]
        this.queryParam.params['endEndDate'] = this.daterangeEndDate[1]
      }
      listPrincipals(this.queryParam).then((response) => {
        this.list = response.rows
        this.total = response.total
        this.loading = false
      })
    },
    // 责任对象类型 责任人负责的对象，系统支持的需要有责任人的对象，目前是车或设备。责任对象是车时，kind=vehicle；责任对象是设备时，kind=equipment。字典翻译
    kindFormat(row, column) {
      return this.selectDictLabel(this.kindOptions, row.kind)
    },
    // 是否删除 0否1是字典翻译
    statusFormat(row, column) {
      return this.selectDictLabel(this.statusOptions, row.status)
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.daterangeStartDate = []
      this.daterangeEndDate = []
      this.queryParam = {
        kind: undefined,
        kindNo: undefined,
        employeeName: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange(current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize(current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map((item) => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced() {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      var that = this
      const ids = row.id || this.ids
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk() {
          return delPrincipals(ids).then(() => {
            that.onSelectChange([], [])
            that.getList()
            that.$message.success('删除成功', 3)
          })
        },
        onCancel() {}
      })
    },
    /** 导出按钮操作 */
    handleExport() {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk() {
          return exportPrincipals(that.queryParam).then((response) => {
            that.download(response.msg)
            that.$message.success('导出成功', 3)
          })
        },
        onCancel() {}
      })
    }
  }
}
</script>
